import * as React from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";
import { List, ListItemButton, ListItemIcon, ListItemText, Box, CssBaseline, Drawer, Toolbar, Stack, Avatar, Collapse, Typography } from '@mui/material';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
// import MailIcon from '@mui/icons-material/Mail';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import CreateIcon from '@mui/icons-material/Create';
import Topbar from "../components/Topbar";
import cfgs from '../cfgs';
import logo from '../img/company_logo.jpg';
// import { store } from "../components/store";

import { ThemeProvider, createTheme } from '@mui/material/styles';

export const DefaultLayout = (props) => {

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  const drawerContent = (
    <List disablePadding>
      <Toolbar sx={{ marginBottom: "20px" }}>
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          justifyContent="center"
        >
          <Avatar src={logo} />
        </Stack>
      </Toolbar>

      {/* <SidebarItem displayText="Dashboard" path="/dashboard" icon={<DashboardIcon />} /> */}
      <SidebarItem displayText="Worksheet" path="/worksheet" icon={<CreateIcon />} />
      <SidebarItem displayText="Report" path="/report" icon={<AnalyticsIcon />} />
      <SidebarDropdown displayText="Settings" icon={<SettingsIcon />}>
          <SidebarItem displayText="Option" path="/option" />
          <SidebarItem displayText="User" path="/user" />
      </SidebarDropdown>
    </List>
  );

  const darkTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  // React.useEffect(() => {
  // });

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Topbar menuClick={handleDrawerToggle} />
      <Box
        component="nav"
        sx={{ width: { sm: cfgs.sidebar.width }, flexShrink: { sm: 0 } }}
        aria-label=""
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: cfgs.sidebar.width, borderRight: "0px", backgroundColor: cfgs.sidebar.bg, color: cfgs.sidebar.color },
          }}
        >
          {drawerContent}
        </Drawer>
        <Drawer
          variant="permanent"
          open
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: cfgs.sidebar.width, borderRight: "0px", backgroundColor: cfgs.sidebar.bg, color: cfgs.sidebar.color },
          }}
        >
          {drawerContent}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${cfgs.sidebar.width}px)` } }}
      >
        <Toolbar />
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Outlet />
        </ThemeProvider>
      </Box>
    </Box>
  )
};

const SidebarItem = (Props) => {
  const { displayText, path, icon } = Props;
  const location = useLocation();

  return (
    <ListItemButton
      component={Link}
      to={path}
      sx={{
        "&: hover": {
          backgroundColor: cfgs.sidebar.hoverBg
        },
        backgroundColor: location.pathname === path ? cfgs.sidebar.activeBg : "unset",
        paddingY: "12px",
        paddingX: "24px"
      }}
    >
      <ListItemIcon sx={{
        color: cfgs.sidebar.color
      }}>
        {icon && icon}
      </ListItemIcon>
        {displayText}
    </ListItemButton>
  );
};

const SidebarDropdown = (Props) => {
  const { displayText, icon, children } = Props;

  const [open, setOpen] = React.useState(true);
  
  return (
    <div>
    <ListItemButton
      onClick={() => setOpen(!open)}
      sx={{
        "&: hover": {
          backgroundColor: cfgs.sidebar.hoverBg
        },
        paddingY: "12px",
        paddingX: "24px"
      }}
    >
      <ListItemIcon sx={{
        color: cfgs.sidebar.color
      }}>
        {icon && icon}
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Typography>
            {displayText}
          </Typography>
        }
      />
      {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}

    </ListItemButton>
    <Collapse in={open} timeout="auto">
      {children}
    </Collapse>
    </div>
  );
};