import { DefaultLayout } from "./DefaultLayout";
import { authenticationService } from '../components/authentication.service';
import { Navigate } from 'react-router-dom';

export const ProtectedLayout = () => {
    const currentUser = authenticationService.currentUserValue;

    if (!currentUser) {
        return <Navigate replace to="/login" />
    }

    return (
        <DefaultLayout />
    );
};