
import { handleResponse } from './handle-response';
import { BehaviorSubject } from 'rxjs';

const _data = new BehaviorSubject(JSON.parse('{}'));

export const settingsService = {
    setData,
    get data() { return _data.value }
};

function setData(url) {
    return fetch(url)
        .then(handleResponse)
        .then(json => {
            _data.next(json);
        });
}