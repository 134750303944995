// import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter, Link } from 'react-router-dom';
import { authenticationService } from './components/authentication.service';
import { settingsService } from './components/settings.service';
import { ProtectedLayout } from './layouts/ProtectedLayout';
import Dashboard from './pages/DashboardPage';
import Login from './pages/LoginPage';
import Option from './pages/OptionPage';
import User from './pages/UserPage';
import Worksheet from './pages/WorksheetPage';
import WorksheetDetail from './pages/WorksheetDetailPage';
import FileUploader from './pages/FileUploader';
import Report from './pages/ReportPage';

function App(props) {

  const [currentUser, setCurrentUser] = useState(null);
  
  useEffect(() => {
    settingsService.setData(props.settingsUrl);
    authenticationService.currentUser.subscribe(x => setCurrentUser(x));
  });

  return (
    <BrowserRouter basename={props.baseUrl}>
      <Routes>
        <Route element={<ProtectedLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/worksheet" element={<Worksheet />} />
          <Route path="/worksheetdetail" element={<WorksheetDetail />} />
          <Route path="/fileuploader" element={<FileUploader />} />
          <Route path="/report" element={<Report />} />
          <Route path="/user" element={<User />} />
          <Route path="/option" element={<Option />} />
          <Route path="/*" element={<NoMatch />} />
        </Route>

        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>

    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
