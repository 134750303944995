import { useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { authenticationService } from '../components/authentication.service';
import { settingsService } from '../components/settings.service';
import { Navigate, useNavigate } from 'react-router-dom';
import logo from '../img/company_logo.jpg';


const validationSchema = yup.object({
    username: yup
      .string('Enter your user name')
      .required('User name is required'),
    password: yup
      .string('Enter your password')
      .min(4, 'Password should be of minimum 4 characters length')
      .required('Password is required'),
  });

function Login() {

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
          username: '',
          password: '',
          remember: false
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
        //   alert(JSON.stringify(values, null, 2));

            authenticationService
                .login(values.username, values.password, settingsService.data.api_url)
                .then(user => {
                    navigate("/worksheet", { replace: true });
                })
                .catch(error => {
                    alert(error);
                });

        },
      });

    if (authenticationService.currentUserValue) {
        return <Navigate to="/worksheet" />
    }

    return (
        <Container component="main" maxWidth="lg">
            <Box sx={{ marginTop: 8 }}>
                <Grid container>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={6}
                        sx={{
                            backgroundImage: `url(${logo})`,
                            backgroundRepeat: "no-repeat",
                            backgroundColor: (t) =>
                                t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                        }}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={6}
                        component={Paper}
                        elevation={6}
                        square
                    >
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Typography component="h1" variant="h5" sx={{ mb: 1 }}>Sign In</Typography>
                            <form onSubmit={formik.handleSubmit}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="username"
                                    name="username"
                                    label="User name"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    error={formik.touched.username && Boolean(formik.errors.username)}
                                    helperText={formik.touched.username && formik.errors.username}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                                <FormControlLabel
                                    control={<Checkbox id="remember" name="remember" onChange={formik.handleChange} value={formik.values.remember} color="primary" />}
                                    label="Remember me"
                                />
                                <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 3, mb: 2 }}>
                                    Submit
                                </Button>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default Login;