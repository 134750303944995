import * as React from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import {
    Box,
    Button,
    ImageList,
    ImageListItem,
    Stack,
    Paper,
    Typography,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    createTheme
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { settingsService } from '../components/settings.service';
import { authenticationService } from '../components/authentication.service';
import { handleResponse } from '../components/handle-response';


function FileUploader() {
    
    const theme = createTheme({});
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    const location = useLocation();
    const navigate = useNavigate();
    const [fileGroups, setFileGroups] = React.useState(location.state.jobFileGroups);
    const [openNewAlbum, setOpenNewAlbum] = React.useState(false);
    const [desc, setDesc] = React.useState("");

    // React.useEffect(() => {
    //     console.log(location.state.jobId);
    //     console.log(fileGroups);
    // }, []);

    const handleClose = () => {
        navigate("/worksheet", { state: { showSuccess: false } });
    }

    const addGroup = async (desc) => {
        const newFileGroup = {
            jobId: location.state.jobId,
            description: desc
        }

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/json'},
            body: JSON.stringify(newFileGroup)
        };

        let response = await fetch(settingsService.data.api_url + "/cust/createJobFileGroup", requestOptions)
                        .then(handleResponse);
        
        return response;
    };

    const deleteGroup = async (id) => {
        const params = new URLSearchParams();
        params.append('id', id);

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/x-www-form-urlencoded'},
            body: params
        };

        let response = await fetch(settingsService.data.api_url + "/cust/deleteJobFileGroup", requestOptions)
                        .then(handleResponse);
        
        return response;
    };

    const addPhoto = async (groupId, files) => {

        const formData = new FormData();
        formData.append("groupId", groupId);

        for (var i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }

        const requestOptions = {
            method: 'POST',
            headers: authenticationService.authHeader,
            body: formData
        };

        let response = await fetch(settingsService.data.api_url + "/cust/createJobFile", requestOptions)
                        .then(handleResponse);

        return response;
    };

    const deletePhoto = async (id) => {
        const params = new URLSearchParams();
        params.append('id', id);

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/x-www-form-urlencoded'},
            body: params
        };

        let response = await fetch(settingsService.data.api_url + "/cust/deleteJobFile", requestOptions)
                        .then(handleResponse);
        
        return response;
    };

    const handleDialogCancel = () => {
        setDesc("");
        setOpenNewAlbum(false);
    }

    const handleDialogOK = () => {
        if (desc === "") {
            alert("Please enter description");
            return;
        }

        addGroup(desc).then((obj) => {
            setFileGroups([...fileGroups, obj]);
            setDesc("");
            setOpenNewAlbum(false);
        }).catch((err) => {
            console.log(err);
            alert(err);
        });
    };

    const handleDeleteGroupClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        deleteGroup(event.target.id).then((obj) => {
            setFileGroups(fileGroups.filter((fileGroup) => fileGroup.id !== obj.id));
        }).catch((err) => {
            console.log(err);
            alert(err);
        });
    };

    const handlePhotoSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const groupId = event.target.getAttribute("groupid");

        addPhoto(groupId, event.target.files).then((data) => {
            let newgroups = fileGroups.map((fileGroup, idx) => {
                if (fileGroup.id === groupId) {
                    return { ...fileGroup, jobFiles : [...fileGroup.jobFiles, ...data]}
                } else {
                    return fileGroup;
                }
            });
            setFileGroups(newgroups);
        })
        .catch((err) => {
            console.log(err);
            alert(err);
        });
    };

    const handleDeletePhotoClick = (event: React.ChangeEvent<HTMLInputElement>, id, groupId) => {

        deletePhoto(id).then((obj) => {
            let newgroups = fileGroups.map((fileGroup, idx) => {
                if (fileGroup.id === groupId) {
                    return { ...fileGroup, jobFiles : fileGroup.jobFiles.filter((jobFile) => jobFile.id !== id)}
                } else {
                    return fileGroup;
                }
            });
            setFileGroups(newgroups);
        }).catch((err) => {
            console.log(err);
            alert(err);
        });
    };

    return (
        <Box maxWidth="lg">
            <Stack direction="column" spacing={2}>
            {
                fileGroups.map((fileGroup, a) => (
                    <Paper variant="elevation" elevation={12} key={a}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt:2, mb: 2, ml:2, mr:2, pb:1, borderBottom: '2px solid #9e9e9e' }}>
                            <Box>
                                <Button id={fileGroup.id} variant="contained" color="error" component="span" sx={{ borderRadius: 6 }}
                                    onClick={handleDeleteGroupClick}>
                                    Remove Album
                                </Button>
                            </Box>
                            <Typography variant="h4" component="div">
                                {fileGroup.description}
                            </Typography>
                            <Box>
                                <label htmlFor={`add-photo-${fileGroup.id}`}>
                                    <input
                                        id={`add-photo-${fileGroup.id}`}
                                        accept="image/*"
                                        type="file"
                                        groupid={fileGroup.id}
                                        multiple
                                        hidden
                                        onChange={handlePhotoSelected}
                                    />
                                    <Button variant="contained" color="success" component="span" sx={{ borderRadius: 6 }}>
                                        <AddIcon /> Add Photo
                                    </Button>
                                </label>
                            </Box>
                        </Box>
                        <ImageList cols={2} rowHeight={280}>
                            {
                                fileGroup.jobFiles.map((jobFile, b) => (
                                    <ImageListItem key={b}>
                                        <img
                                            src={`data:${jobFile.fileType};base64, ${jobFile.fileData}`}
                                            // src={`data:image/jpeg;base64, ${jobFile.fileData}?w=300&fit=clip&auto=format`}
                                            alt={jobFile.fileName}
                                            loading="lazy"
                                            style={{ maxHeight: "280px", objectFit: "contain" }}
                                        />
                                        <Button variant="text"
                                            sx={{ position: "absolute", top: 2, right: 2, borderRadius: 6, p: 1}}
                                            onClick={(event) => handleDeletePhotoClick(event, jobFile.id, fileGroup.id)}>
                                            <RemoveCircleOutlineIcon fontSize="large" />
                                        </Button>
                                    </ImageListItem>
                                ))       
                            }
                        </ImageList>
                        
                    </Paper>
                ))
            }
            </Stack>
            <Box sx={{ mt: 4, textAlign:"right" }}>
                <Button variant="contained" size="large" color="error" onClick={handleClose} sx={{ mr: 6, borderRadius: 6 }} >
                    Close
                </Button>
                <Button variant="contained" size="large" color="primary" onClick={() => setOpenNewAlbum(true)} sx={{ mr: 2, borderRadius: 6 }} >
                    <AddIcon /> Add Album
                </Button>
            </Box>
            <Dialog open={openNewAlbum} onClose={handleDialogCancel} fullScreen={fullScreen}>
                <DialogTitle>New Album</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="tfDesc"
                        label="Description"
                        fullWidth
                        variant="standard"
                        value={desc}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setDesc(event.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancel}>Cancel</Button>
                    <Button onClick={handleDialogOK}>Submit</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default FileUploader;