import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useMediaQuery, createTheme } from '@mui/material';


export interface ConfirmationDialogProps {
  id: string;
  keepMounted: boolean;
  open: boolean;
  title: string;
  content: string;
  onCloseCancel: () => void;
  onCloseOK: () => void;
}

function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { onCloseCancel, onCloseOK, open, title, content, ...other } = props;

  const theme = createTheme({});
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleEntering = () => {
    console.log('handleEntering');
  };

  const handleCancel = () => {
    onCloseCancel();
  };

  const handleOk = () => {
    onCloseOK();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      fullScreen={fullScreen}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ mb: 2 }}>{content}</Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
