import Container from "@mui/material/Container";
import Button from '@mui/material/Button';

function Option() {

    return (
        <div>
            <h1>Option</h1>
            <Container>
            </Container>
        </div>
    )
}

export default Option;