import * as React from 'react';
import moment from "moment";
import { Button, Box, Stack, Divider, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, createTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import KeyIcon from '@mui/icons-material/Key';
import { settingsService } from '../components/settings.service';
import { authenticationService } from '../components/authentication.service';
import { handleResponse } from '../components/handle-response';
import ConfirmationDialog from '../components/ConfirmationDialog';

import { DataGrid, GridRowModes, GridActionsCellItem, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';


function User() {

    const theme = createTheme({});
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [gridData, setGridData] = React.useState([]);
    const [rowEditModes, setRowEditModes] = React.useState({});
    const [rowSelection, setRowSelection] = React.useState([]);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openPwd, setOpenPwd] = React.useState(false);
    const [openDelConfirm, setOpenDelConfirm] = React.useState(false);

    const [userId, setUserId] = React.useState("");
    const [pwd, setPwd] = React.useState("");
    
    React.useEffect(() => {
        fetchData();
        // console.log("useEffect");
    }, []);

    const fetchData = () => {
        const requestOptions = {
            method: 'GET',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/json'}
        };

        fetch(settingsService.data.api_url + "/users?sort=username", requestOptions)
        .then(handleResponse)
        .then((data) => {
            // data._embedded.users.forEach((row, idx) => {
            //     row.createDt = new Date(row.createDt);
            // });
            setGridData(data._embedded.users)
        });
    };

    const changePassword = (id, password) => {

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/json'},
            body: JSON.stringify({id, password})
        };

        fetch(settingsService.data.api_url + "/user/changePassword", requestOptions)
        .then(handleResponse)
        .then((updatedRow) => {
            setPwd("");
            setOpenPwd(false);
            setOpenSnackbar(true);
            setGridData(gridData.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
        });
    };

    const createUser = async (row) => {

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/json'},
            body: JSON.stringify(row)
        };

        let response = await fetch(settingsService.data.api_url + "/user/createUser", requestOptions).then(handleResponse);
        return response;
    };

    const updateUser = async (row) => {

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/json'},
            body: JSON.stringify(row)
        };

        let response = await fetch(settingsService.data.api_url + "/user/updateUser", requestOptions).then(handleResponse);
        return response;
    };

    const deleteUser = async (id) => {

        const params = new URLSearchParams();
        params.append('id', id);

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/x-www-form-urlencoded'},
            body: params
        };

        let response = await fetch(settingsService.data.api_url + "/user/deleteUser", requestOptions).then(handleResponse);
        return response;
    };

    const columns = [
        {
            field: 'firstname',
            headerName: 'First Name',
            minWidth: 100, flex: 1,
            editable: true
            // align: 'left',
            // headerAlign: 'left'
        },
        {
            field: 'lastname',
            headerName: 'Last Name',
            minWidth: 100, flex: 1,
            editable: true
        },
        {
            field: 'username',
            headerName: 'Username',
            minWidth: 100, flex: 1,
            editable: true
        },
        {
            field: 'roleId',
            headerName: 'Role',
            minWidth: 100, flex: 1,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['ADMIN', 'USER']
        },
        {
            field: 'createDt',
            headerName: 'Create Date',
            // type: 'dateTime',
            valueGetter: ({ value }) => value && moment(value).format('YYYY-MM-DD hh:mm:ss'),
            minWidth: 100, flex: 1.5
        },
        {
            field: 'createBy',
            headerName: 'Create By',
            minWidth: 100, flex: 1
        },
        {
            field: 'updateDt',
            headerName: 'Update Date',
            // type: 'date',
            valueGetter: ({ value }) => value && moment(value).format('YYYY-MM-DD hh:mm:ss'),
            minWidth: 100, flex: 1.5
        },
        {
            field: 'updateBy',
            headerName: 'Update By',
            minWidth: 100, flex: 1
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            minWidth: 100, flex: 1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isEditing = Object.values(rowEditModes).find(row => row.mode === GridRowModes.Edit);

                if (isEditing) {

                    const isInEditMode = rowEditModes[id]?.mode === GridRowModes.Edit;

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label="Save"
                                sx={{color: 'primary.main'}}
                                onClick={handleSaveClick(id)}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id)}
                                color="inherit"
                            />
                        ];
                    } else {
                        return [];
                    }
                } else {
                    return [
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<KeyIcon />}
                            label="Change Password"
                            className="textPrimary"
                            onClick={handleChangePwdButtonClick(id)}
                            color="inherit"
                        />,
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={handleDeleteClick(id)}
                            color="inherit"
                        />
                    ];
                }
                
    
                
            }
        }
    ];

    const handleAddClick = () => {
        const id = randomId();
        const newData = { id, firstname:'', lastname:'', username:'', roleId:'', createDt:'', createBy:'', updateDt:'', updateBy:'',  isNew: true };
        const newRowEditMode = { mode: GridRowModes.Edit, fieldToFocus: 'firstname' }

        if (!Object.values(rowEditModes).find(row => row.mode === GridRowModes.Edit)) {

            let idx = 0;
            gridData.map((a, _idx) => {
                if (a.id === rowSelection[0]) {
                    idx = _idx + 1;
                }
                return null;
            });

            setGridData((oldRows) => [
                ...oldRows.slice(0, idx),
                newData,
                ...oldRows.slice(idx),
            ]);
            setRowEditModes((oldModel) => ({
                ...oldModel,
                [id]: newRowEditMode
            }));

        }
        
    };

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        setRowEditModes({ ...rowEditModes, [id]: { mode: GridRowModes.Edit } });
    };
    
    const handleSaveClick = (id) => () => {
        setRowEditModes({ ...rowEditModes, [id]: { mode: GridRowModes.View } });
    };
    
    const handleDeleteClick = (id) => () => {
        setUserId(id);
        setOpenDelConfirm(true);
    };

    const handleDelCfmCancel = () => {
        setOpenDelConfirm(false);
    };

    const handleDelCfmOK = () => {
        setOpenDelConfirm(false);
        deleteUser(userId).then((deletedRow) => {
            setOpenSnackbar(true);
            setGridData(gridData.filter((row) => row.id !== userId));
        });;
    };
    
    const handleCancelClick = (id) => () => {
        setRowEditModes({...rowEditModes, [id]: { mode: GridRowModes.View, ignoreModifications: true } })
        const editedRow = gridData.find((row) => row.id === id);
        if (editedRow.isNew) {
            setGridData(gridData.filter((row) => row.id !== id));
        }
    };

    const handleChangePwdButtonClick = (id) => () => {
        setUserId(id);
        setOpenPwd(true);
    };

    const processRowUpdate = (newRow) => {
        console.log("processRowUpdate");

        let promise;
        if (newRow.isNew) {
            promise = createUser(newRow);
        } else {
            promise = updateUser(newRow);
        }

        return promise.then((returnRow) => {
            const updatedRow = { ...returnRow, isNew: false };
            setOpenSnackbar(true);
            setGridData(gridData.map((row) => (row.id === newRow.id ? updatedRow : row)));
            return updatedRow;
        });
    };

    const handleRowEditModesChange = (newRowEditModes) => {
        setRowEditModes(newRowEditModes);
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
    };

    const CustomToolbar = () => {
        return (
          <GridToolbarContainer>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                // justifyContent="space-between" width="100%"
                spacing={4}
            >
                <Box>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                </Box>
                <Box>
                    <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>Add</Button>
                </Box>
            </Stack>
          </GridToolbarContainer>
        );
    };


    return (
        <Box>
            <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose} anchorOrigin={{ vertical:'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Saved successfully!
                </Alert>
            </Snackbar>

            <DataGrid editMode="row"
                rows={gridData}
                columns={columns}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelection(newRowSelectionModel);
                }}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                rowSelectionModel={rowSelection}
                rowModesModel={rowEditModes}
                onRowModesModelChange={handleRowEditModesChange}
                processRowUpdate={processRowUpdate}
                slots={{ toolbar: CustomToolbar }} />
            
            <Dialog open={openPwd} onClose={() => { setPwd(""); setOpenPwd(false); }} fullScreen={fullScreen}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="tfPassword"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        value={pwd}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPwd(event.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setPwd(""); setOpenPwd(false); }}>Cancel</Button>
                    <Button onClick={() => { changePassword(userId, pwd)}}>Submit</Button>
                </DialogActions>
            </Dialog>

            <ConfirmationDialog
                id="delete-confirmation-dialog"
                keepMounted
                open={openDelConfirm}
                onCloseCancel={handleDelCfmCancel}
                onCloseOK={handleDelCfmOK}
                title="Confirmation"
                content="Are you sure you want to delete this user?"
            />
        </Box>
    )
}


  

export default User;