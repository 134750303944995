import { BehaviorSubject } from 'rxjs';
import { handleResponse } from './handle-response';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value },
    get authHeader() {
        const currentUser = this.currentUserValue;
        if (currentUser && currentUser.token) {
            return { Authorization: `${currentUser.token}` };
        } else {
            return {};
        }
    }
};

function login(username, password, apiurl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    return fetch(apiurl + `/user/login`, requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject("User name or password is incorrect!");
            }
            return response.json();
        })
        .then(user => {
            user["username"] = username;
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}