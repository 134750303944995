import * as React from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import moment from "moment";
import {
    Button,
    Box,
    Stack,
    Divider,
    Snackbar,
    Alert
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { settingsService } from '../components/settings.service';
import { authenticationService } from '../components/authentication.service';
import { handleResponse } from '../components/handle-response';

import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from '@mui/x-data-grid';

function Worksheet() {

    const location = useLocation();
    const navigate = useNavigate();
    const [gridData, setGridData] = React.useState([]);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    React.useEffect(() => {

        if (location.state !== null &&
            location.state.showSuccess !== null &&
            location.state.showSuccess !== undefined &&
            location.state.showSuccess === true) {
            setOpenSnackbar(true);
        }

        fetchData();
    }, []);

    const fetchData = () => {
        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/json'},
            body: JSON.stringify({})
        };

        fetch(settingsService.data.api_url + "/cust/ws2/findJob", requestOptions)
        .then(handleResponse)
        .then((data) => {
            setGridData(data)
        });
    };

    const fetchJobScheme = async () => {

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/json'},
            body: JSON.stringify({})
        };

        let response = await fetch(settingsService.data.api_url + "/cust/ws2/getJobScheme", requestOptions)
                        .then(handleResponse);
        
        return response;
    };

    const fetchJob = async (id) => {
        const params = new URLSearchParams();
        params.append('id', id);

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/x-www-form-urlencoded'},
            body: params
        };

        let response = await fetch(settingsService.data.api_url + "/cust/ws2/findById", requestOptions)
                        .then(handleResponse);
        
        return response;
    };

    const fetchJobFileGroups = async (id) => {
        const params = new URLSearchParams();
        params.append('jobId', id);

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/x-www-form-urlencoded'},
            body: params
        };

        let response = await fetch(settingsService.data.api_url + "/cust/getJobFileGroupsByJobId", requestOptions)
                        .then(handleResponse);
        
        return response;
    };

    const columns = [
        {
            field: 'jobNo',
            headerName: 'Job No.',
            width: 150
            // minWidth: 50,
            // flex: 1
        },
        {
            field: 'customer',
            headerName: 'Customer',
            width: 200
            // minWidth: 50,
            // flex: 1
        },
        {
            field: 'placeOfSurvey',
            headerName: 'Place of Survey',
            width: 200
            // minWidth: 50,
            // flex: 1
        },
        {
            field: 'typeOfSurvey',
            headerName: 'Type of Survey',
            valueGetter: ({ value }) => {
                if (value === 'REFRIGERATED_CONTAINER_CARGO')
                    return 'REFRIGERATED CONTAINER CARGO (DEVANNING)';
                else if (value === 'DRY_CONTAINER_CARGO')
                    return 'DRY CONTAINER CARGO (DEVANNING)';
                else
                    return value;
            },
            width: 250
            // minWidth: 50,
            // flex: 1
        },
        {
            field: 'surveyDt',
            headerName: 'Survey Date',
            valueGetter: ({ value }) => value && moment(value).format('YYYY-MM-DD hh:mm:ss'),
            width: 150
            // minWidth: 50,
            // flex: 1.5
        },
        {
            field: 'appointedDt',
            headerName: 'Appointed Date',
            valueGetter: ({ value }) => value && moment(value).format('YYYY-MM-DD hh:mm:ss'),
            width: 150
            // minWidth: 50,
            // flex: 1.5
        },
        // {
        //     field: 'surveyor',
        //     headerName: 'Surveyor'
        // },
        // {
        //     field: 'checker',
        //     headerName: 'Checker'
        // },
        {
            field: 'createDt',
            headerName: 'Create Date',
            valueGetter: ({ value }) => value && moment(value).format('YYYY-MM-DD hh:mm:ss'),
            width: 150
            // minWidth: 100,
            // flex: 1.5
        },
        {
            field: 'createBy',
            headerName: 'Create By',
            width: 100
            // minWidth: 50,
            // flex: 1
        },
        {
            field: 'updateDt',
            headerName: 'Update Date',
            valueGetter: ({ value }) => value && moment(value).format('YYYY-MM-DD hh:mm:ss'),
            width: 150
            // minWidth: 100,
            // flex: 1.5
        },
        {
            field: 'updateBy',
            headerName: 'Update By',
            width: 100
            // minWidth: 50,
            // flex: 1
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            // minWidth: 50,
            // flex: 1,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<CameraAltIcon />}
                        label="Photo"
                        className="textPrimary"
                        onClick={handlePhotoClick(id)}
                        color="inherit"
                    />
                ];
            }
        }
    ];

    const handleAddClick = () => {
        fetchJobScheme().then((jobData) => {
            navigate("/worksheetdetail", { replace: false, state: { jobData: jobData, isEdit: false } });
        });
    };

    const handleEditClick = (id) => () => {
        fetchJob(id).then((jobData) => {
            navigate("/worksheetdetail", { replace: false, state: { jobData: jobData, isEdit: true } });
        });
    };

    const handlePhotoClick = (id) => () => {
        fetchJobFileGroups(id).then((jobFileGroups) => {
            navigate("/fileuploader", { replace: false, state: { jobId: id, jobFileGroups: jobFileGroups } });
        });
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
    };

    const CustomToolbar = () => {
        return (
          <GridToolbarContainer>
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                // justifyContent="space-between" width="100%"
                spacing={4}
            >
                <Box>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                </Box>
                <Box>
                    <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>Add</Button>
                </Box>
            </Stack>
          </GridToolbarContainer>
        );
    };

    return (
        <Box>
            <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleSnackbarClose} anchorOrigin={{ vertical:'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Saved successfully!
                </Alert>
            </Snackbar>

            <DataGrid
                sx={{ '& .MuiDataGrid-cellContent': { whiteSpace: 'normal', lineHeight: 'normal' } }}
                density="comfortable"
                rows={gridData}
                columns={columns}
                slots={{ toolbar: CustomToolbar }} />
        </Box>
    )
}

export default Worksheet;