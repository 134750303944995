import Container from "@mui/material/Container";
import Button from '@mui/material/Button';
import { authenticationService } from '../components/authentication.service';

function DashboardPage() {

    return (
        <div>
            <h1>Dashboard</h1>
            <Container>
                <Button onClick={() => {
                    authenticationService.logout();
                    alert("Logged out");
                }}>Logout</Button>
            </Container>
        </div>
    )
}

export default DashboardPage;