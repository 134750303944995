import * as React from 'react';

import moment from "moment";
import {
    Box
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { settingsService } from '../components/settings.service';
import { authenticationService } from '../components/authentication.service';
import { handleResponse } from '../components/handle-response';

import {
    DataGrid,
    GridToolbar,
    GridActionsCellItem
} from '@mui/x-data-grid';

function Report() {

    const [gridData, setGridData] = React.useState([]);

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        const requestOptions = {
            method: 'GET',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/json'}
        };

        fetch(settingsService.data.api_url + "/reports", requestOptions)
        .then(handleResponse)
        .then((data) => {
            setGridData(data._embedded.reports)
        });
    };

    const downloadReport = async (id, fileName) => {

        const params = new URLSearchParams();
        params.append('reportId', id);

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/x-www-form-urlencoded'},
            body: params
        };

        await fetch(settingsService.data.api_url + "/cust/reportDownload", requestOptions)
        .then((response) => response.blob())
        .then((blob) => {
            var url = window.URL.createObjectURL(blob);
            var fileLink = document.createElement('a');
            fileLink.href = url;
            fileLink.download = fileName;
            document.body.appendChild(fileLink);
            fileLink.click();
            fileLink.remove();
        })
        .catch((error) => {
            alert(error);
        });

    };

    const handleDownloadClick = (id) => () => {
        let row = gridData.find(x => x.id === id);
        downloadReport(id, row.fileName);
    };

    const columns = [
        // {
        //     field: 'jobNo',
        //     headerName: 'Job No.',
        //     width: 150
        // },
        // {
        //     field: 'customer',
        //     headerName: 'Customer',
        //     width: 200
        // },
        // {
        //     field: 'typeOfSurvey',
        //     headerName: 'Type of Survey',
        //     width: 200
        // },
        {
            field: 'certNo',
            headerName: 'Cert No.',
            width: 150
        },
        {
            field: 'fileName',
            headerName: 'File Name',
            width: 200
        },
        {
            field: 'createDt',
            headerName: 'Create Date',
            valueGetter: ({ value }) => value && moment(value).format('YYYY-MM-DD hh:mm:ss'),
            width: 200
        },
        {
            field: 'createBy',
            headerName: 'Create By',
            width: 100
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<CloudDownloadIcon />}
                        label="Save"
                        sx={{color: 'primary.main'}}
                        onClick={handleDownloadClick(id)}
                    />
                ];
            }
        }
    ];

    return (
        <Box>
            <DataGrid
                sx={{ '& .MuiDataGrid-cellContent': { whiteSpace: 'normal', lineHeight: 'normal' } }}
                rows={gridData}
                columns={columns}
                slots={{ toolbar: GridToolbar }} />
        </Box>
    )
}

export default Report;