import * as React from 'react';
import { Button, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

import { DataGrid, GridRowModes, GridActionsCellItem, GridToolbarContainer } from '@mui/x-data-grid';
import { randomId } from '@mui/x-data-grid-generator';

function DataGridMod(props) {

    const actionColumn = {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        cellClassName: 'actions',
        width: 100,
        getActions: ({ id }) => {
            const isEditing = Object.values(rowEditModes).find(row => row.mode === GridRowModes.Edit);

            if (isEditing) {

                const isInEditMode = rowEditModes[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{color: 'primary.main'}}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />
                    ];
                } else {
                    return [];
                }
            } else {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />
                ];
            }
            
        }
    };

    const { rowsProp, columnsProp, processAdd, processDelete, processUpdate } = props;
    const [rows, setRows] = React.useState(rowsProp);
    const [columns, setColumns] = React.useState([...columnsProp, actionColumn]);

    
    const [toAddRow, setToAddRow] = React.useState("");
    const [toAddIndex, setToAddIndex] = React.useState("");
    const [toUpdateRow, setToUpdateRow] = React.useState("");
    const [toDeleteID, setToDeleteID] = React.useState("");
    
    const [rowEditModes, setRowEditModes] = React.useState({});
    const [rowSelection, setRowSelection] = React.useState([]);

    React.useEffect(() => {
        setColumns(current => [...columnsProp, actionColumn]);
    }, [rowEditModes]);

    React.useEffect(() => {
        processAdd(toAddIndex, toAddRow);
    }, [toAddRow]);

    React.useEffect(() => {
        processUpdate(toUpdateRow);
    }, [toUpdateRow]);

    React.useEffect(() => {
        processDelete(toDeleteID);
    }, [toDeleteID]);

    const handleAddClick = () => {
        const id = randomId();
        
        const newData = { id: id, isNew: true };
        columnsProp.map((column) => {
            newData[column.field] = '';
            return null;
        });

        const newRowEditMode = { mode: GridRowModes.Edit }


        if (!Object.values(rowEditModes).find(row => row.mode === GridRowModes.Edit)) {

            let idx = 0;
            rows.map((a, _idx) => {
                if (a.id === rowSelection[0]) {
                    idx = _idx + 1;
                }
                return null;
            });

            setToAddIndex(idx);

            setRows((oldRows) => [
                ...oldRows.slice(0, idx),
                newData,
                ...oldRows.slice(idx),
            ]);
            setRowEditModes((oldModel) => ({
                ...oldModel,
                [id]: newRowEditMode
            }));
        }
    };

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        setRowEditModes({ ...rowEditModes, [id]: { mode: GridRowModes.Edit } });
    };
    
    const handleSaveClick = (id) => () => {
        setRowEditModes({ ...rowEditModes, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        setToDeleteID(id);
        setRows(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id) => () => {
        setRowEditModes({...rowEditModes, [id]: { mode: GridRowModes.View, ignoreModifications: true } })
        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const handleRowEditModesChange = (newRowEditModes) => {
        setRowEditModes(newRowEditModes);
    };

    const processRowUpdate = (newRow) => {
        console.log("processRowUpdate");
        const {isNew, ...newRowCopy} = newRow;
        if (newRow.isNew) {
            setToAddRow(newRowCopy);
        } else {
            setToUpdateRow(newRowCopy);
        }

        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const CustomToolbar = () => {
        return (
          <GridToolbarContainer>
            <Box>
                <Button color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>Add</Button>
            </Box>
          </GridToolbarContainer>
        );
    };

    return (
        <Box>
            <DataGrid editMode="row"
                sx={{ '& .MuiDataGrid-cellContent': { whiteSpace: 'normal', lineHeight: 'normal' } }}
                density="comfortable"
                rows={rows}
                columns={columns}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelection(newRowSelectionModel);
                }}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                rowSelectionModel={rowSelection}
                rowModesModel={rowEditModes}
                onRowModesModelChange={handleRowEditModesChange}
                processRowUpdate={processRowUpdate}
                slots={{ toolbar: CustomToolbar }} />
        </Box>
    );
}

export default DataGridMod;