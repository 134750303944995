import * as React from 'react';
import { useLocation } from "react-router-dom";
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import cfgs from '../cfgs';
import { authenticationService } from './authentication.service';

const Topbar = (props) => {

    const location = useLocation();
    const { menuClick } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfile = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        authenticationService.logout();
        setAnchorEl(null);
    };

    const getTitle = () => {
        let path = location.pathname;
        if (path && path.length > 1) {
            let str = path.slice(1);
            str = str.charAt(0).toUpperCase() + str.slice(1);
            return str;
        } else {
            return "";
        }
    };

    const currentUser = authenticationService.currentUserValue;

    return (
        <AppBar
            position="fixed"
            color="primary"
            sx={{
                width: { sm: `calc(100% - ${cfgs.sidebar.width})`},
                ml: { sm: cfgs.sidebar.width},
                boxShadow: "unset",
                // backgroundColor: cfgs.topbar.bg,
                // color: cfgs.topbar.color
            }}
        >
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick= {menuClick}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {getTitle()}
                </Typography>
                <Typography variant="h9" component="div" sx={{ flexGrow: 0 }}>
                    {currentUser.username}
                </Typography>
                <div>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      sx={{ mt: '35px' }}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleProfile}>Profile</MenuItem>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Topbar;