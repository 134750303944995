import { colors } from "@mui/material";

const cfgs = {
  sidebar: {
    width: "240px",
    bg: "#233044",
    color: "#eeeeee",
    hoverBg: "#1e293a",
    activeBg: "#1e253a"
  },
  topbar: {
    bg: "#aaa",
    color: "#000"
  },
  mainBg: colors.grey["100"]
};

export default cfgs;