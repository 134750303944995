import * as React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {
    Button,
    ButtonGroup,
    Box,
    Stepper,
    Step,
    StepLabel,
    StepButton,
    Typography,
    Divider,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    createTheme
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import cfgs from '../cfgs';
import { settingsService } from '../components/settings.service';
import { authenticationService } from '../components/authentication.service';
import { handleResponse } from '../components/handle-response';
import DataGridMod from '../components/DataGridMod';

function WorksheetDetailPage() {

    const theme = createTheme({});
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const location = useLocation();
    const navigate = useNavigate();
    const [jobData, setJobData] = React.useState(location.state.jobData);
    const [activeStep, setActiveStep] = React.useState(0);
    const [typeOfSurvey, setTypeOfSurvey] = React.useState(location.state.jobData.typeOfSurvey);
    const [openGen, setOpenGen] = React.useState(false);
    const [certNo, setCertNo] = React.useState("");

    // React.useEffect(() => {
    //     console.log("useEffect");
    // }, []);

    const createJob = async (job) => {

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/json'},
            body: JSON.stringify(job)
        };

        let response = await fetch(settingsService.data.api_url + "/cust/ws2/createJob", requestOptions)
                        .then(handleResponse);
        
        return response;
    };

    const updateJob = async (job) => {

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/json'},
            body: JSON.stringify(job)
        };

        let response = await fetch(settingsService.data.api_url + "/cust/ws2/updateJob", requestOptions)
                        .then(handleResponse);
        
        return response;
    };

    const fetchJobFileGroups = async (id) => {
        const params = new URLSearchParams();
        params.append('jobId', id);

        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/x-www-form-urlencoded'},
            body: params
        };

        let response = await fetch(settingsService.data.api_url + "/cust/getJobFileGroupsByJobId", requestOptions)
                        .then(handleResponse);
        
        return response;
    };

    const generateReport = async () => {
            
        const requestOptions = {
            method: 'POST',
            headers: { ...authenticationService.authHeader, 'Content-Type': 'application/json'},
            body: JSON.stringify({jobId: jobData.id ,certNo: certNo})
        };

        let response = await fetch(settingsService.data.api_url + "/cust/ws2/generateReport", requestOptions)
                        .then(handleResponse);
        
        return response;
    }

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleNext = () => {
        setActiveStep((step) => {
            return step + 1;
        });
    };

    const handleBack = () => {
        setActiveStep((step) => {
            return step - 1;
        });
    };

    const handleCancel = () => {
        navigate("/worksheet", { state: { showSuccess: false } });
    }

    const handleSave = () => {
        console.log(jobData);
        if (jobData.id === null) {
            createJob(jobData).then((data) => {
                console.log(data);
                navigate("/worksheet", { state: { showSuccess: true } });
            });
        } else {
            updateJob(jobData).then((data) => {
                console.log(data);
                navigate("/worksheet", { state: { showSuccess: true } });
            });
        }
    };

    const handlePhoto = () => {
        if (jobData.id === null) {
            createJob(jobData).then((data) => {
                fetchJobFileGroups(data.id).then((jobFileGroups) => {
                    navigate("/fileuploader", { replace: false, state: { jobId: data.id, jobFileGroups: jobFileGroups } });
                });
            });
        } else {
            updateJob(jobData).then((data) => {
                fetchJobFileGroups(jobData.id).then((jobFileGroups) => {
                    navigate("/fileuploader", { replace: false, state: { jobId: jobData.id, jobFileGroups: jobFileGroups } });
                });
            });
        }
    };


    const handleDialogCancel = () => {
        setCertNo("");
        setOpenGen(false);
    }

    const handleDialogOK = () => {
        if (certNo === "") {
            alert("Please enter certificate number");
            return;
        }

        if (jobData.id === null) {
            createJob(jobData).then((resp1) => {
                generateReport().then((resp2) => {
                    navigate(-1);
                });
            });
        } else {
            updateJob(jobData).then((resp1) => {
                generateReport().then((resp2) => {
                    navigate(-1);
                });
            });
        }
    };

    const columns= React.useMemo(() => [
        {
            field: 'marks',
            headerName: 'Marks',
            width: 150,
            editable: true
        },
        {
            field: 'packages',
            headerName: 'Packages',
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            width: 100,
            editable: true
        },
        {
            field: 'contents',
            headerName: 'Contents',
            width: 300,
            editable: true
        },
        {
            field: 'packing',
            headerName: 'Packing',
            width: 200,
            editable: true
        },
        {
            field: 'extCondition',
            headerName: 'Ext. Condition',
            width: 300,
            editable: true
        },
        {
            field: 'intCondition',
            headerName: 'Int. Condition',
            width: 300,
            editable: true
        }
    ], []);


    return (
        <>
        <Box sx={{ pb: 6 }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ my: 2 }}>
                <Step>
                    <StepButton color="inherit" onClick={handleStep(0)}>
                        <StepLabel>General Information</StepLabel>
                    </StepButton>
                </Step>
                <Step>
                    <StepButton color="inherit" onClick={handleStep(1)}>
                        <StepLabel>Particular of shipment</StepLabel>
                    </StepButton>
                </Step>
                <Step>
                    <StepButton color="inherit" onClick={handleStep(2)}>
                        <StepLabel>Findings</StepLabel>
                    </StepButton>
                </Step>
                <Step>
                    <StepButton color="inherit" onClick={handleStep(3)}>
                        <StepLabel>Remarks</StepLabel>
                    </StepButton>
                </Step>
            </Stepper>

            <Divider variant="middle" />
                
            <Box component="form" sx={{ my: 2, mx: 2,
                '& .MuiTextField-root': { mt: 1, mb: 2 , width: '100%' },
                '& .MuiFormControl-root': { mt: 1, mb: 2 , width: '100%' } }} >

                {activeStep === 0 && (<>
                <Typography variant="h5" component="div" sx={{textAlign: 'center', textDecoration: 'underline', marginBottom: 1}}>
                    General Information
                </Typography>
                <Typography variant="h6" component="div">
                    Job No.
                </Typography>
                <TextField
                    required
                    id="jobNo"
                    label="Required"
                    value={jobData.jobNo}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, 'jobNo': event.target.value});
                    }}
                    // InputProps={{
                    //     readOnly: true,
                    // }}
                />
                <Typography variant="h6" component="div">
                    Customer
                </Typography>
                <TextField
                    required
                    id="customer"
                    label="Required"
                    value={jobData.customer}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, 'customer': event.target.value});
                    }}
                />
                <Typography variant="h6" component="div">
                    Place Of Survey
                </Typography>
                <TextField
                    required
                    id="placeOfSurvey"
                    label="Required"
                    value={jobData.placeOfSurvey}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, 'placeOfSurvey': event.target.value});
                    }}
                />
                <Typography variant="h6" component="div">
                    Survey Date Time
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        ampm={false}
                        format={'DD MMM YYYY HH:mm'}
                        value={dayjs(jobData.surveyDt)}
                        slotProps={{ textField: { variant: 'filled' } }}
                        onChange={(newValue) => {
                            setJobData({ ...jobData, 'surveyDt': newValue.toISOString()});
                        }}
                    />
                </LocalizationProvider>
                <Typography variant="h6" component="div">
                    Appointed Date Time
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        ampm={false}
                        format={'DD MMM YYYY HH:mm'}
                        value={dayjs(jobData.appointedDt)}
                        slotProps={{ textField: { variant: 'filled' } }}
                        onChange={(newValue) => {
                            setJobData({ ...jobData, 'appointedDt': newValue.toISOString()});
                        }}
                    />
                </LocalizationProvider>
                <Typography variant="h6" component="div">
                    Type Of Survey
                </Typography>
                <FormControl required variant="filled">
                    <InputLabel id="typeOfSurveylabel">Required</InputLabel>
                    <Select
                        labelId="typeOfSurvey-label"
                        id="typeOfSurvey"
                        value={typeOfSurvey}
                        onChange={(event) => {
                            setTypeOfSurvey(event.target.value);
                            setJobData({ ...jobData, 'typeOfSurvey': event.target.value});
                        }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="REFRIGERATED_CONTAINER_CARGO">REFRIGERATED CONTAINER CARGO (DEVANNING)</MenuItem>
                        <MenuItem value="DRY_CONTAINER_CARGO">DRY CONTAINER CARGO (DEVANNING)</MenuItem>
                    </Select>
                </FormControl>
                </> )}

                {activeStep === 1 && (<>
                <Typography variant="h5" component="div" sx={{textAlign: 'center', textDecoration: 'underline', marginBottom: 1}}>
                    Particular of shipment
                </Typography>
                <Typography variant="h6" component="div">
                    Carriage
                </Typography>
                <TextField
                    required
                    id="carriage"
                    label="Required"
                    value={jobData.jobWs2Shipment.carriage}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, carriage : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Voyage
                </Typography>
                <TextField
                    required
                    id="voyage"
                    label="Required"
                    value={jobData.jobWs2Shipment.voyage}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, voyage : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Port Of Loading
                </Typography>
                <TextField
                    required
                    id="portOfLoading"
                    label="Required"
                    value={jobData.jobWs2Shipment.portOfLoading}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, portOfLoading : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Port Of Discharge
                </Typography>
                <TextField
                    required
                    id="portOfDischarge"
                    label="Required"
                    value={jobData.jobWs2Shipment.portOfDischarge}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, portOfDischarge : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Date Of Arrival
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        format={'DD MMM YYYY'}
                        value={dayjs(jobData.jobWs2Shipment.dateOfArrival)}
                        slotProps={{ textField: { variant: 'filled' } }}
                        onChange={(newValue) => {
                            setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, dateOfArrival : newValue.toISOString()}});
                        }}
                    />
                </LocalizationProvider>
                <Typography variant="h6" component="div">
                    Supplier
                </Typography>
                <TextField
                    required
                    id="supplier"
                    label="Required"
                    value={jobData.jobWs2Shipment.supplier}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, supplier : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Consignee
                </Typography>
                <TextField
                    required
                    id="consignee"
                    label="Required"
                    value={jobData.jobWs2Shipment.consignee}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, consignee : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Bill of Lading No.
                </Typography>
                <TextField
                    required
                    id="billNo"
                    label="Required"
                    value={jobData.jobWs2Shipment.billNo}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, billNo : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Ocean Bill of Lading No.
                </Typography>
                <TextField
                    required
                    id="oceanBillNo"
                    label="Required"
                    value={jobData.jobWs2Shipment.oceanBillNo}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, oceanBillNo : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Invoice No.
                </Typography>
                <TextField
                    required
                    id="invoiceNo"
                    label="Required"
                    value={jobData.jobWs2Shipment.invoiceNo}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, invoiceNo : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    P.O. No.
                </Typography>
                <TextField
                    required
                    id="poNo"
                    label="Required"
                    value={jobData.jobWs2Shipment.poNo}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, poNo : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Quantity
                </Typography>
                <TextField
                    required
                    id="quantity"
                    label="Required"
                    value={jobData.jobWs2Shipment.quantity}
                    variant="filled"
                    type="number"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, quantity : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Container No.
                </Typography>
                <TextField
                    required
                    id="containerNo"
                    label="Required"
                    value={jobData.jobWs2Shipment.containerNo}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, containerNo : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Container Size
                </Typography>
                <TextField
                    required
                    id="containerSize"
                    label="Required"
                    value={jobData.jobWs2Shipment.containerSize}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, containerSize : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Seal No(s).
                </Typography>
                <TextField
                    required
                    multiline
                    id="sealNo"
                    label="Required"
                    value={jobData.jobWs2Shipment.sealNo}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, sealNo : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Condition of Seal
                </Typography>
                <TextField
                    required
                    multiline
                    id="sealCondition"
                    label="Required"
                    value={jobData.jobWs2Shipment.sealCondition}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Shipment : { ...jobData.jobWs2Shipment, sealCondition : event.target.value } });
                    }}
                />
                </> )}

                {activeStep === 2 && (<>
                <Typography variant="h5" component="div" sx={{textAlign: 'center', textDecoration: 'underline', marginBottom: 1}}>
                    Findings
                </Typography>
                <DataGridMod
                    rowsProp={jobData.jobWs2Findings}
                    columnsProp={columns}
                    processAdd={(idx, newRow) => setJobData({ ...jobData, jobWs2Findings : [
                            ...jobData.jobWs2Findings.slice(0, idx),
                            newRow,
                            ...jobData.jobWs2Findings.slice(idx),
                        ]})
                    }
                    processUpdate={(newRow) => setJobData({ ...jobData, jobWs2Findings : jobData.jobWs2Findings.map((row) => (row.id === newRow.id ? newRow : row)) })}
                    processDelete={(id) => setJobData({ ...jobData, jobWs2Findings : jobData.jobWs2Findings.filter((row) => row.id !== id)})}
                />
                </> )}

                {activeStep === 3 && (<>
                <Typography variant="h5" component="div" sx={{textAlign: 'center', textDecoration: 'underline', marginBottom: 1}}>
                    Remarks
                </Typography>
                <Typography variant="h6" component="div">
                    Discrepancies as per shipping documents
                </Typography>
                <TextField
                    required
                    multiline
                    id="discrepancies"
                    label="Required"
                    value={jobData.jobWs2Remark.discrepancies}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Remark : { ...jobData.jobWs2Remark, discrepancies : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Cargo Stowage
                </Typography>
                <TextField
                    required
                    multiline
                    id="stowage"
                    label="Required"
                    value={jobData.jobWs2Remark.stowage}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Remark : { ...jobData.jobWs2Remark, stowage : event.target.value } });
                    }}
                />
                <Typography variant="h6" component="div">
                    Attribution
                </Typography>
                <TextField
                    required
                    multiline
                    id="attribution"
                    label="Required"
                    value={jobData.jobWs2Remark.attribution}
                    variant="filled"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setJobData({ ...jobData, jobWs2Remark : { ...jobData.jobWs2Remark, attribution : event.target.value } });
                    }}
                />
                
                </> )}
            </Box>
            

            {/* <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
            </Box> */}
        </Box>
        <Box sx={{ p: 2,
                borderTop: 1, borderColor: 'rgba(0, 0, 0, 0.12)',
                position: 'fixed', bottom: 0, zIndex: 999, textAlign: 'right',
                width: { sm: `calc(100% - ${cfgs.sidebar.width} - 40px)` }, backgroundColor: 'white' }}>
            <Button variant="contained" color="error" onClick={handleCancel} sx={{ mr: 2, borderRadius: 6 }} >
                Cancel
            </Button>
            <ButtonGroup sx={{ mr : 2}}>
                <Button variant="contained" color="secondary" onClick={handleBack} sx={{ mr: 1, borderRadius: 6  }} disabled={activeStep === 0}>
                    Back
                </Button>
                <Button variant="contained" color="secondary" onClick={handleNext} sx={{ borderRadius: 6  }} disabled={activeStep === 3}>
                    Next
                </Button>
            </ButtonGroup>
            <Button variant="contained" onClick={handleSave} sx={{ mr: 2, borderRadius: 6 }} >
                Save
            </Button>
            <Button variant="contained" onClick={handlePhoto} sx={{ mr: 2, borderRadius: 6 }} >
                Save & Photo
            </Button>
            <Button variant="contained" color="success" onClick={() => { setOpenGen(true) }} sx={{ borderRadius: 6 }} >
                Generate Report
            </Button>
        </Box>
        <Dialog open={openGen} onClose={handleDialogCancel} fullScreen={fullScreen}>
            <DialogTitle>Report</DialogTitle>
            <DialogContent>
                <Typography variant="h6" component="div">
                    Cert No.
                </Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="tfCertNo"
                    label="CertNo"
                    fullWidth
                    variant="standard"
                    value={certNo}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setCertNo(event.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogCancel}>Cancel</Button>
                <Button onClick={handleDialogOK}>Submit</Button>
            </DialogActions>
        </Dialog>
        </>
    );

}

export default WorksheetDetailPage;